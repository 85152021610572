'use client';

import { createContext } from 'react';
import { useEffect, useState } from 'react';
import { AMPLITUDE_CONFIG } from '@/constants/config';
import { Amplitude } from '.';
export const AmplitudeContext = createContext<null | Amplitude>(null);
interface Props {
  children: React.ReactNode;
}
export default function ClientAmplitudeProvider({
  children
}: Props) {
  const [amplitude, setAmplitude] = useState<Amplitude | null>(null);
  useEffect(() => {
    setAmplitude(new Amplitude(AMPLITUDE_CONFIG.apiKey, AMPLITUDE_CONFIG.options));
  }, []);
  if (!amplitude) return null;
  return <AmplitudeContext.Provider value={amplitude} data-sentry-element="unknown" data-sentry-component="ClientAmplitudeProvider" data-sentry-source-file="provider.tsx">{children}</AmplitudeContext.Provider>;
}