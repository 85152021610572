'use client';

import * as Menubar from '@radix-ui/react-menubar';
import { SubjectWithCourse } from 'entity-convert/entity/subject-with-course';
import Link from 'next/link';
import { useState } from 'react';
import { paths } from '@/routes/paths';
import { cnm } from '@/utils/cnm';
interface DropdownMenuProps {
  subjectList: SubjectWithCourse[];
  children: React.ReactNode;
}
export default function DropdownMenu({
  children,
  subjectList
}: DropdownMenuProps) {
  const [subjectId, setSubjectId] = useState(`${subjectList?.[0]?.id}`);
  const courseList = subjectList.find(subject => subject.id === Number(subjectId))?.courses;
  const [courseId, setCourseId] = useState(`${courseList?.[0]?.id}`);
  return <Menubar.Root data-sentry-element="unknown" data-sentry-component="DropdownMenu" data-sentry-source-file="dropdown-menu.tsx">
      <Menubar.Menu data-sentry-element="unknown" data-sentry-source-file="dropdown-menu.tsx">
        <Menubar.Trigger className="group relative flex gap-2 items-center focus:outline-none" data-sentry-element="unknown" data-sentry-source-file="dropdown-menu.tsx">
          {children}
        </Menubar.Trigger>
        <Menubar.Portal data-sentry-element="unknown" data-sentry-source-file="dropdown-menu.tsx">
          <Menubar.Content className="bg-bg-white p-4 rounded-m shadow-xs flex gap-4 h-full z-dropdown" align="start" sideOffset={30} alignOffset={-3} data-sentry-element="unknown" data-sentry-source-file="dropdown-menu.tsx">
            <div>
              <Menubar.RadioGroup value={subjectId} onValueChange={setSubjectId} data-sentry-element="unknown" data-sentry-source-file="dropdown-menu.tsx">
                {subjectList.map(subject => <Menubar.RadioItem key={subject.id} className={cnm('pl-4 pr-2 mb-1 min-w-[155px] flex justify-between items-center text-content-inverseTertiary cursor-pointer rounded-lg', 'data-[state=checked]:text-content-inversePrimary data-[state=checked]:outline data-[state=checked]:outline-1 data-[state=checked]:outline-border-inversePrimary', 'focus:outline focus:outline-1 focus:outline-border-inversePrimary')} value={`${subject.id}`} onSelect={e => {
                e.preventDefault();
              }}>
                    {subject.name}
                    <Menubar.ItemIndicator>{'>'}</Menubar.ItemIndicator>
                  </Menubar.RadioItem>)}
              </Menubar.RadioGroup>
            </div>
            <Menubar.Separator className="w-[1px] bg-border-inverseQuaternary" data-sentry-element="unknown" data-sentry-source-file="dropdown-menu.tsx" />
            <div>
              <Menubar.RadioGroup value={courseId} onValueChange={setCourseId} data-sentry-element="unknown" data-sentry-source-file="dropdown-menu.tsx">
                {courseList?.map(course => <Link href={paths.questionBank.root({
                courseId: Number(course.id)
              })} passHref key={`course-${course.id}`}>
                    <Menubar.RadioItem className="text-content-inverseTertiary data-[state=checked]:text-content-inversePrimary cursor-pointer focus:outline-none" value={`${course.id}`}>
                      {course.name} {course.level}
                    </Menubar.RadioItem>
                  </Link>)}
              </Menubar.RadioGroup>
            </div>
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
    </Menubar.Root>;
}