import Image from 'next/image';
import { SVImageProps } from './types';
import { getImageSize, getImageUrl } from './util';
export default function SVImage({
  size = 'xxxlg',
  name,
  alt,
  className
}: SVImageProps) {
  const _size = getImageSize(size);
  return <Image src={getImageUrl({
    name
  })} width={_size} height={_size} alt={alt ?? name ?? 'image'} className={className} data-sentry-element="Image" data-sentry-component="SVImage" data-sentry-source-file="index.tsx" />;
}