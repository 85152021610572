import { useSuspenseInfiniteQuery, useSuspenseQuery } from '@tanstack/react-query';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Course, toCourse } from 'entity-convert/entity/course';
import { GroupedSubject, toGroupedSubject } from 'entity-convert/entity/grouped-subject';
import { toQuestion } from 'entity-convert/entity/question';
import { toQuestionAttempt } from 'entity-convert/entity/question-attempt';
import { QuestionListParams, toQuestionListParams } from 'entity-convert/entity/question-list-params';
import { toQuestionRead } from 'entity-convert/entity/question-read';
import { toQuestionSubjectOverview } from 'entity-convert/entity/question-subject-overview';
import { toSubTopic } from 'entity-convert/entity/sub-topic';
import { toSubjectWithCourse } from 'entity-convert/entity/subject-with-course';
import { toTopic } from 'entity-convert/entity/topic';
import { getQuestionBankGroupedSubjectsListQueryKey, getQuestionBankQuestionListQueryKey, getQuestionBankQuestionStatusCountRetrieveQueryKey, questionBankGroupedSubjectsList, questionBankQuestionList, questionBankQuestionStatusCountRetrieve, useQuestionBankAttemptRetrieve, useQuestionBankCourseList, useQuestionBankCourseRetrieve, useQuestionBankQuestionList, useQuestionBankQuestionRetrieve, useQuestionBankSubjectList, useQuestionBankSubjectRetrieve, useQuestionBankSubtopicList, useQuestionBankSubtopicRetrieve, useQuestionBankTopicList, useQuestionBankTopicRetrieve } from 'studyvibes-api/orval/endpoints/studyVibesRESTAPI';
export const useGetSubjectList = ({
  inService
}: {
  inService?: boolean;
} = {}) => {
  return useQuestionBankSubjectList({
    in_service: inService
  }, {
    query: {
      select: data => {
        const parsed = data.map(toSubjectWithCourse);
        const hasCoursesSubject = parsed?.filter(subject => subject.courses?.length > 0);
        return hasCoursesSubject;
      }
    }
  });
};
export const useGetSubject = (id: number, {
  inService = true
}: {
  inService: boolean;
} | undefined) => {
  return useQuestionBankSubjectRetrieve(id, {
    in_service: inService
  }, {
    query: {
      select: data => toSubjectWithCourse(data),
      enabled: Boolean(id)
    }
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InferGroupByReturnType<G extends ((response: Course[]) => any) | undefined> = G extends ((response: Course[]) => infer R) ? R : Course[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useGetCourseList = <GroupBy extends ((response: Course[]) => any) | undefined,>({
  subjectId,
  inService
}: {
  subjectId?: number;
  inService?: boolean;
}, groupBy?: GroupBy) => {
  return useQuestionBankCourseList({
    subject: subjectId,
    in_service: inService
  }, {
    query: {
      select: (data): InferGroupByReturnType<GroupBy> => {
        const parse = data?.map(toCourse);
        return groupBy ? groupBy(parse) : parse as InferGroupByReturnType<GroupBy>;
      }
    }
  });
};
export const useGetCourse = (id: number) => {
  return useQuestionBankCourseRetrieve(id, {
    query: {
      select: data => toCourse(data),
      enabled: Boolean(id)
    }
  });
};
export const useGetTopicList = (courseId?: number) => {
  return useQuestionBankTopicList({
    course: courseId
  }, {
    query: {
      select: data => data.map(toTopic)
    }
  });
};
export const useGetTopic = (id: number) => {
  return useQuestionBankTopicRetrieve(id, {
    query: {
      select: data => toTopic(data),
      enabled: Boolean(id)
    }
  });
};
export const useGetSubTopicList = (topicId: number) => {
  return useQuestionBankSubtopicList({
    topic: topicId
  }, {
    query: {
      select: data => data.map(toSubTopic)
    }
  });
};
export const useGetSubTopic = (id: number) => {
  return useQuestionBankSubtopicRetrieve(id, {
    query: {
      select: data => toSubTopic(data),
      enabled: Boolean(id)
    }
  });
};
export const useGetQuestionList = (params: QuestionListParams) => {
  return useQuestionBankQuestionList(toQuestionListParams(params), {
    query: {
      select: data => {
        const items = data.items.map(toQuestionRead);
        return {
          ...data,
          items
        };
      }
    }
  });
};
export const useGetInfinityQuestionList = (params: QuestionListParams) => {
  return useSuspenseInfiniteQuery({
    queryKey: getQuestionBankQuestionListQueryKey(toQuestionListParams(params)),
    queryFn: async ({
      pageParam = 0
    }) => {
      try {
        const data = await questionBankQuestionList({
          ...toQuestionListParams(params),
          offset: pageParam
        });
        return data;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        return {
          items: [],
          paging: {
            page: 0,
            size: 0,
            total: 0
          }
        };
      }
    },
    initialPageParam: 0,
    getNextPageParam: lastPage => lastPage?.paging?.next,
    select: data => {
      const items = data.pages.flatMap(page => page.items.map(toQuestionRead))?.filter(Boolean);
      return {
        ...data,
        items
      };
    }
  });
};
export const useGetQuestion = (id: number) => {
  return useQuestionBankQuestionRetrieve(id, {
    query: {
      select: toQuestion
    }
  });
};
export const useGetQuestionStatusCount = (subjectId: number) => {
  return useSuspenseQuery({
    queryKey: getQuestionBankQuestionStatusCountRetrieveQueryKey(subjectId),
    queryFn: async () => {
      try {
        const data = await questionBankQuestionStatusCountRetrieve(subjectId);
        return toQuestionSubjectOverview(data);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        return {};
      }
    }
  });
};
export const useGetQuestionAttempt = (questionId: number) => {
  return useQuestionBankAttemptRetrieve(questionId, {
    query: {
      select: toQuestionAttempt,
      enabled: Boolean(questionId)
    }
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InferGroupedSubjectByReturnType<G extends ((response: GroupedSubject[]) => any) | undefined> = G extends ((response: GroupedSubject[]) => infer R) ? R : GroupedSubject[];
export const useGetGroupedSubjectList = <
// eslint-disable-next-line @typescript-eslint/no-explicit-any
GroupBy extends ((response: GroupedSubject[]) => any) | undefined,>({
  inService
}: {
  inService: boolean;
}, groupBy?: GroupBy) => {
  return useSuspenseQuery({
    queryKey: getQuestionBankGroupedSubjectsListQueryKey({
      in_service: inService
    }),
    queryFn: async (): Promise<InferGroupedSubjectByReturnType<GroupBy>> => {
      try {
        const data = await questionBankGroupedSubjectsList({
          in_service: inService
        });
        const parse = data?.map(toGroupedSubject);
        return groupBy ? groupBy(parse) : parse as InferGroupedSubjectByReturnType<GroupBy>;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        return [] as InferGroupedSubjectByReturnType<GroupBy>;
      }
    }
  });
};