'use client';

import { cnm } from '@/utils/cnm';
import { textAreaStyle } from './styles';
import { TextAreaProps } from './types';
function TextArea({
  id,
  label,
  error,
  hintText,
  className,
  ...props
}: TextAreaProps) {
  return <div className="flex flex-col gap-[6px]" data-sentry-component="TextArea" data-sentry-source-file="index.tsx">
      <label className="!text-b1sb text-gray-700" htmlFor={id || label}>
        {label}
      </label>
      <textarea id={id || label} className={cnm(textAreaStyle({
      error
    }), className)} autoComplete="off" {...props} />
      <div className={cnm('!text-b2', error ? 'text-content-error' : 'text-gray-600')}>
        {hintText}
      </div>
    </div>;
}
export default TextArea;