import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { getScheduleTimeTrackingListQueryOptions } from 'studyvibes-api/orval/endpoints/studyVibesRESTAPI';
import { usePostTimeTracking } from '@/operation/api/schedule/mutate';
import { getSessionStorage, removeSessionStorage, setSessionStorage } from '@/utils/storage';
type TrackTime = {
  seconds: number;
  isPaused: boolean;
};
const TIMER = 'track-time';

// ------------------------------
/**
 * 초 단위를 받아서 HH:mm:ss 형식의 문자열로 변환하는 함수
 *
 * @param rowSeconds 변환할 초 단위 값
 * @returns "HH:mm:ss" 형식의 문자열
 */
const formatSeconds = (rowSeconds: number) => {
  const hours = Math.floor(rowSeconds / 3600);
  const minutes = Math.floor(rowSeconds % 3600 / 60);
  const remainingSeconds = rowSeconds % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
};

/**
 * 지난 초(rowSeconds)를 기준으로 시작 시각(startTime)과
 * 종료 시각(endTime)을 ISO String 형태로 반환하는 함수
 *
 * @param rowSeconds 과거로부터 경과한 초
 * @returns
 *  - startTime: 현재 시각에서 rowSeconds만큼 이전 시점의 ISO 문자열
 *  - endTime: 현재 시각의 ISO 문자열
 */
const formatTimeHistory = (rowSeconds: number) => {
  return {
    startTime: dayjs().subtract(rowSeconds, 'seconds').toISOString(),
    endTime: dayjs().toISOString()
  };
};

// ------------------------------
/**
 * @description
 *  - 1초 간격으로 시간을 카운트하며, start와 pause 함수를 통해 타이머를 제어할 수 있습니다.
 *  - SessionStorage(`TIMER`)에 현재 시간(초)과 일시정지 여부를 저장하여
 *    새로고침 시에도 카운트된 시간을 복원합니다.
 *  - pause 함수를 호출하면 서버(postTimeTracking API)에 해당 시간 기록을 저장하고,
 *    카운트를 0초로 초기화합니다.
 *
 * @returns {object} 다음 프로퍼티를 포함하는 객체:
 *  - start: 타이머(초 카운트)를 시작하는 함수
 *  - pause: 타이머를 일시정지하고, 서버에 시간 기록을 저장하며 카운트를 초기화하는 함수
 *  - formattedTime: HH:mm:ss 형식으로 포맷된 현재 카운트된 시간
 *  - isPaused: 현재 타이머가 일시정지 상태인지 여부
 *  - rawSeconds: 실제 누적된 시간(초 단위)
 *  - isPendingPostTimeTracking: 시간 기록을 서버에 저장하는 요청이 진행 중인지 여부
 */
const useTrackTime = () => {
  const intervalRef = useRef<NodeJS.Timeout | null>();
  const queryClient = useQueryClient();
  const {
    mutateAsync: postTimeTracking
  } = usePostTimeTracking();
  const [seconds, setSeconds] = useState(0);
  const [isPaused, setIsPaused] = useState(true);
  const [isPendingPostTimeTracking, setPendingPostTimeTracking] = useState(false);
  const start = useCallback(() => {
    if (intervalRef.current) return;
    setIsPaused(false);
    intervalRef.current = setInterval(() => {
      setSeconds(prev => {
        setSessionStorage(TIMER, {
          seconds: prev + 1,
          isPaused: false
        });
        return prev + 1;
      });
    }, 1000);
  }, []);
  const pause = useCallback(async (courseId?: number, notes?: string) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    setPendingPostTimeTracking(true);
    const time = getSessionStorage<TrackTime>(TIMER);
    const {
      startTime,
      endTime
    } = formatTimeHistory(time?.seconds ?? 0);
    try {
      await postTimeTracking({
        courseId,
        startTime: new Date(startTime),
        endTime: new Date(endTime),
        notes
      });
      await queryClient.invalidateQueries(getScheduleTimeTrackingListQueryOptions());
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      toast.error('Failed to save time tracking');
      console.error(e);
    } finally {
      setPendingPostTimeTracking(false);
    }
    setIsPaused(true);
    setSeconds(0);
    removeSessionStorage(TIMER);
  }, []);
  useEffect(() => {
    const timer = getSessionStorage<TrackTime>(TIMER);
    if (timer) {
      setSeconds(Number(timer.seconds));
      if (!timer.isPaused) {
        setTimeout(start, 0); // Macro task Queue 에 넣어서 순서 보장
      }
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [start]);
  return {
    start,
    pause,
    formattedTime: formatSeconds(seconds),
    isPaused,
    rawSeconds: seconds,
    isPendingPostTimeTracking
  };
};
export default useTrackTime;