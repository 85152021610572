import { forwardRef } from 'react';
import { cnm } from '@/utils/cnm';
import { wrapperStyle } from './styles';
import { ButtonProps } from './types';
const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  children,
  size,
  variant,
  leadingIcon,
  trailingIcon,
  className,
  shape,
  type = 'button',
  iconOnly,
  ...props
}, ref) => {
  return <button className={cnm(wrapperStyle({
    size,
    variant,
    shape,
    iconOnly
  }), className)} type={type} ref={ref} {...props}>
        {leadingIcon}
        {children}
        {trailingIcon}
      </button>;
});
Button.displayName = 'Button';
export default Button;