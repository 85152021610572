export const AMPLITUDE_CONFIG = {
  apiKey: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
  options: {
    autocapture: {
      attribution: true,
      pageViews: true,
      sessions: true,
      formInteractions: false,
      fileDownloads: false,
      elementInteractions: false,
    },
  },
};
