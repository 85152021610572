import type { RefObject } from 'react';
import { useEffect, useRef } from 'react';
import { useIsomorphicLayoutEffect } from './use-isomorphic-layout-effect';

// MediaQueryList Event based useEventListener interface
function useEventListener<K extends keyof MediaQueryListEventMap>(eventName: K, handler: (event: MediaQueryListEventMap[K]) => void, element: RefObject<MediaQueryList>, options?: boolean | AddEventListenerOptions): void;

// Window Event based useEventListener interface
function useEventListener<K extends keyof WindowEventMap>(eventName: K, handler: (event: WindowEventMap[K]) => void, element?: undefined, options?: boolean | AddEventListenerOptions): void;

// Element Event based useEventListener interface
function useEventListener<K extends keyof HTMLElementEventMap, T extends HTMLElement = HTMLDivElement>(eventName: K, handler: (event: HTMLElementEventMap[K]) => void, element: RefObject<T>, options?: boolean | AddEventListenerOptions): void;

// Document Event based useEventListener interface
function useEventListener<K extends keyof DocumentEventMap>(eventName: K, handler: (event: DocumentEventMap[K]) => void, element: RefObject<Document>, options?: boolean | AddEventListenerOptions): void;

/**
 * DOM 요소, window 또는 미디어 쿼리 리스트에 이벤트 리스너를 연결하기 위한 커스텀 훅입니다.
 * @template KW - window 이벤트의 타입입니다.
 * @template KH - HTML 요소 이벤트의 타입입니다.
 * @template KM - 미디어 쿼리 리스트 이벤트의 타입입니다.
 * @template T - DOM 요소의 타입 (기본값은 `HTMLElement`입니다).
 * @param {KW | KH | KM} eventName - 감시할 이벤트의 이름입니다.
 * @param {(event: WindowEventMap[KW] | HTMLElementEventMap[KH] | MediaQueryListEventMap[KM] | Event) => void} handler - 이벤트 핸들러 함수입니다.
 * @param {RefObject<T>} [element] - 이벤트 리스너를 연결할 DOM 요소나 미디어 쿼리 리스트 (선택 사항)입니다.
 * @param {boolean | AddEventListenerOptions} [options] - 이벤트 리스너에 대한 설정 옵션 객체 (선택 사항)입니다.
 * @see [Documentation](https://usehooks-ts.com/react-hook/use-event-listener)
 * @example
 * // 예시 1: window 이벤트 리스너 연결
 * useEventListener('resize', handleResize);
 * @example
 * // 예시 2: document 이벤트 리스너 연결 (옵션 포함)
 * const elementRef = useRef(document);
 * useEventListener('click', handleClick, elementRef, { capture: true });
 * @example
 * // 예시 3: 요소 이벤트 리스너 연결
 * const buttonRef = useRef<HTMLButtonElement>(null);
 * useEventListener('click', handleButtonClick, buttonRef);
 */

function useEventListener<KW extends keyof WindowEventMap, KH extends keyof HTMLElementEventMap, KM extends keyof MediaQueryListEventMap, T extends HTMLElement | MediaQueryList = HTMLElement>(eventName: KW | KH | KM, handler: (event: WindowEventMap[KW] | HTMLElementEventMap[KH] | MediaQueryListEventMap[KM] | Event) => void, element?: RefObject<T>, options?: boolean | AddEventListenerOptions) {
  // Create a ref that stores handler
  const savedHandler = useRef(handler);
  useIsomorphicLayoutEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  useEffect(() => {
    // Define the listening target
    const targetElement: T | Window = element?.current ?? window;
    if (!(targetElement && targetElement.addEventListener)) return;

    // Create event listener that calls handler function stored in ref
    const listener: typeof handler = event => {
      savedHandler.current(event);
    };
    targetElement.addEventListener(eventName, listener, options);

    // Remove event listener on cleanup
    return () => {
      targetElement.removeEventListener(eventName, listener, options);
    };
  }, [eventName, element, options]);
}
export { useEventListener };