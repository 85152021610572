'use client';

import { toUserDetail } from 'entity-convert/entity/user-auth-detail';
import React, { useEffect, useRef } from 'react';
import { accountGoogleCreate } from 'studyvibes-api/orval/endpoints/studyVibesRESTAPI';
import { cnm } from '@/utils/cnm';
import { useAuth } from './auth-provider';
const GoogleLoginProvider = ({
  children,
  className
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const {
    login
  } = useAuth();
  const googleRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    document.body.appendChild(script);
    script.onload = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const {
        google
      } = window as any;
      google.accounts.id.initialize({
        client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
        callback: onSignIn,
        auto_select: false
      });
      google.accounts.id.renderButton(googleRef.current, {
        width: '9999px',
        // 최대 값이 400px 까지 밖에 안됨 // https://developers.google.com/identity/gsi/web/reference/js-reference?hl=ko#IdConfiguration
        size: 'large'
      });
    };
    return () => {
      const scripts = document.querySelectorAll('script[src="https://accounts.google.com/gsi/client"]');
      scripts.forEach(script => script.remove());
    };
  }, []);
  const onSignIn = async (response: {
    clientId: string;
    client_id: string;
    credential: string;
    select_by: string;
  }) => {
    const {
      access,
      refresh,
      user
    } = await accountGoogleCreate({
      id_token: response.credential
    });
    login({
      access,
      refresh,
      user: toUserDetail(user)
    });
  };
  return <div className={cnm('relative w-fit', className)} data-sentry-component="GoogleLoginProvider" data-sentry-source-file="google-login-provider.tsx">
      <div id="google-login-button" ref={googleRef} className="absolute *:overflow-hidden w-full *:w-full h-full opacity-[0]" />
      <div>{children}</div>
    </div>;
};
export default GoogleLoginProvider;