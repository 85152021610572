'use client';

import { QueryClient, QueryClientProvider as Provider } from '@tanstack/react-query';
import { ReactNode } from 'react';
function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        refetchIntervalInBackground: false,
        retryOnMount: false,
        staleTime: Infinity
      }
    }
  });
}
let browserQueryClient: QueryClient | undefined = undefined;
function getQueryClient() {
  if (typeof window === 'undefined') {
    return makeQueryClient();
  } else {
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}
export default function QueryClientProvider({
  children
}: {
  children: ReactNode;
}) {
  const client = getQueryClient();
  return <Provider client={client} data-sentry-element="Provider" data-sentry-component="QueryClientProvider" data-sentry-source-file="query-client-provider.tsx">{children}</Provider>;
}