import { forwardRef } from 'react';
import { cnm } from '@/utils/cnm';
import { textFieldStyle } from './styles';
import { TextFieldProps } from './types';
const TextField = forwardRef<HTMLInputElement, TextFieldProps>(({
  id,
  label,
  leadingIcon,
  trailingIcon,
  error,
  hintText,
  className,
  ...props
}, ref) => {
  return <div>
        <label className="!text-b1sb text-gray-700 mb-[6px]" htmlFor={id || label}>
          {label}
        </label>
        <div className={cnm(textFieldStyle({
      error
    }), className)}>
          {leadingIcon}
          <input id={id || label} ref={ref} className="text-field placeholder-content-inverseSecondary grow focus:outline-none max-w-full" autoComplete="off" {...props} />
          {trailingIcon}
        </div>
        {hintText && <div className={cnm('mt-[6px] !text-b2', error ? 'text-content-error' : 'text-gray-600')}>
            {hintText}
          </div>}
      </div>;
});
TextField.displayName = 'TextField';
export default TextField;