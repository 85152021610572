'use client';

import { useEffect, useState } from 'react';
interface ClientSideRenderProps {
  children: React.ReactNode;
}
export default function ClientSideRender({
  children
}: ClientSideRenderProps) {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  return <>{isClient && children}</>;
}