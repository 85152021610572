import Link from 'next/link';
import { paths } from '@/routes/paths';
import Button from '@/ui/component/button';
import SVImage from '@/ui/component/sv-image';
import { B1b, H5b } from '@/ui/component/typography';
interface UnlimitedStudyVibesProps {
  onClose?: () => void;
}
export default function UnlimitedStudyVibes({
  onClose
}: UnlimitedStudyVibesProps) {
  return <div className="flex flex-col gap-10 items-center" data-sentry-component="UnlimitedStudyVibes" data-sentry-source-file="index.tsx">
      <div className="flex flex-col gap-[10px] items-center">
        <SVImage name="graduateBooks" size="xlg" alt="Unlimited Question Bank" data-sentry-element="SVImage" data-sentry-source-file="index.tsx" />
        <H5b data-sentry-element="H5b" data-sentry-source-file="index.tsx">
          If you become a paid member,
          <br />
          you can use all the features!
        </H5b>
        <div>
          <B1b className="text-content-inverseSecondary list-disc" as="li" data-sentry-element="B1b" data-sentry-source-file="index.tsx">
            Flashcards
          </B1b>
          <B1b className="text-content-inverseSecondary list-disc" as="li" data-sentry-element="B1b" data-sentry-source-file="index.tsx">
            Track your studytime
          </B1b>
          <B1b className="text-content-inverseSecondary list-disc" as="li" data-sentry-element="B1b" data-sentry-source-file="index.tsx">
            Calendar
          </B1b>
        </div>
      </div>
      <div className="flex flex-col gap-3 items-center">
        <Link href={paths.signUp.root} passHref data-sentry-element="Link" data-sentry-source-file="index.tsx">
          <Button onClick={onClose} variant="primary" size="lg" shape="circle" data-sentry-element="Button" data-sentry-source-file="index.tsx">
            Become a StudyViber +
          </Button>
        </Link>
        <Link href={paths.tutoring.root} passHref data-sentry-element="Link" data-sentry-source-file="index.tsx">
          <Button onClick={onClose} variant="primary" size="lg" shape="circle" data-sentry-element="Button" data-sentry-source-file="index.tsx">
            Become a Tutoring
          </Button>
        </Link>
      </div>
    </div>;
}