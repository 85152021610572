'use client';

import { useRef, useState } from 'react';
import { useAuth } from '@/provider/auth-provider';
import Button from '@/ui/component/button';
import { Modal, ModalProvider, ModalTrigger } from '@/ui/component/modal';
import SVIcon from '@/ui/component/sv-icon';
import { useOnClickOutside } from '@/ui/hook/use-on-click-outside';
import UnlimitedStudyVibes from '../unlimited-study-vibes';
import TrackTimeModal from './track-time-modal';
export default function TrackTime() {
  const {
    userInfo = {}
  } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const timeTrackRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside([timeTrackRef], () => setIsOpen(false));
  if (!userInfo?.membership) {
    return <div className="flex gap-2 items-end z-[9999] fixed bottom-10 right-10">
        <ModalProvider open={isOpen} onOpenChange={setIsOpen}>
          <ModalTrigger asChild>
            <Button onClick={() => setIsOpen(!isOpen)} iconOnly shape="square" variant="primary" size="m">
              <SVIcon name="TimerMOutlined" />
            </Button>
          </ModalTrigger>
          <Modal>
            <UnlimitedStudyVibes onClose={() => setIsOpen(false)} />
          </Modal>
        </ModalProvider>
      </div>;
  }
  return <div ref={timeTrackRef} className="flex gap-2 items-end z-[9999] fixed bottom-10 right-10" data-sentry-component="TrackTime" data-sentry-source-file="index.tsx">
      {isOpen && <TrackTimeModal />}
      <Button onClick={() => setIsOpen(!isOpen)} iconOnly shape="square" variant="primary" size="m" data-sentry-element="Button" data-sentry-source-file="index.tsx">
        <SVIcon name="TimerMOutlined" data-sentry-element="SVIcon" data-sentry-source-file="index.tsx" />
      </Button>
    </div>;
}