import * as Dialog from '@radix-ui/react-dialog';
import React from 'react';
import { cnm } from '@/utils/cnm';
import SVIcon from '../sv-icon';
interface ModalProps extends Dialog.DialogContentProps {
  closeButton?: boolean;
  onClose?: () => void;
}
export function Modal({
  children,
  closeButton,
  className,
  onClose,
  ...props
}: ModalProps) {
  return <Dialog.Portal data-sentry-element="unknown" data-sentry-component="Modal" data-sentry-source-file="index.tsx">
      <Dialog.Overlay className="fixed inset-0 bg-bg-zIndex-popup z-modal" onPointerUp={onClose} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
      <Dialog.Title data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
      <Dialog.Description data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
      <Dialog.Content className={cnm('z-modal fixed flex flex-col left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-lg bg-bg-white px-10 py-8 focus:outline-none', 'max-h-[90%] hide-scrollbar', className)} {...props} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        {closeButton && <Dialog.Close className="self-end">
            <SVIcon name="XLOutlined" />
          </Dialog.Close>}
        {children}
      </Dialog.Content>
    </Dialog.Portal>;
}
export default Object.assign(Modal, {
  Title: Dialog.Title,
  Description: Dialog.Description,
  Close: Dialog.Close
});
export const ModalProvider = Dialog.Root;
export const ModalTrigger = Dialog.Trigger;