import { StatusDeaEnum } from 'studyvibes-api/orval/models';

import { tutoringSteps } from '@/ui/page/tutoring';

import { PaymentPrevPage } from './types';

type ArrayElement<T extends readonly unknown[]> = T[number];

const roots = {
  home: '/',
  'sign-up': '/sign-up',
  'question-bank': '/question-bank',
  tutoring: '/tutoring',
  'my-account': '/my-account',
  complete: '/complete',
  payment: '/payment',
};

export const paths = {
  home: roots.home,
  signUp: {
    root: roots['sign-up'],
    plus: `${roots['sign-up']}/plus`,
  },
  questionBank: {
    root: ({ courseId }: { courseId: number }) => `${roots['question-bank']}?courseId=${courseId}`,
    questionList: ({
      subjectCourse,
      topic,
      subTopic,
      subTopicId,
    }: {
      subjectCourse: string;
      topic: string;
      subTopic: string;
      subTopicId: number;
    }) =>
      `${roots['question-bank']}/question-list?subjectCourse=${subjectCourse}&topic=${topic}&subTopic=${subTopic}&subTopicId=${subTopicId}`,
  },
  tutoring: {
    root: roots.tutoring,
    step: (step: ArrayElement<typeof tutoringSteps>) => `${roots.tutoring}?step=${step}`,
  },
  myAccount: {
    root: `${roots['my-account']}/profile`,
    profile: `${roots['my-account']}/profile`,
    questionBank: {
      root: `${roots['my-account']}/questionbank`,
      subjects: (courseId: number) =>
        `${roots['my-account']}/questionbank/subjects?courseId=${courseId}`,
    },
    tutoring: {
      root: `${roots['my-account']}/tutoring`,
      bookingHistory: (recommendId: number) =>
        `${roots['my-account']}/tutoring/${recommendId}/booking-history`,
      matching: (id: string, status: StatusDeaEnum | 'rematch') =>
        `${roots['my-account']}/tutoring/matching/${id}?status=${status}`,
      rematching: (id: string) => `${roots['my-account']}/tutoring/matching/${id}/rematch`,
    },
    membership: {
      root: `${roots['my-account']}/membership`,
      paymentMethod: (searchParams: { subscriptionId: string; subjectName?: string }) =>
        `${roots['my-account']}/membership/payment-method?subscriptionId=${searchParams?.subscriptionId}&subjectName=${searchParams?.subjectName}`,
    },
    billing: `${roots['my-account']}/billing`,
    grade: {
      root: `${roots['my-account']}/grade`,
      new: (subjectId: number, courseName?: string) =>
        `${roots['my-account']}/grade/assignment/new?subjectId=${subjectId}&courseName=${courseName}`,
      edit: (
        type: 'exam' | 'assignment',
        id: number,
        searchParams?: { semesterId?: number; courseName?: string },
      ) =>
        `${roots['my-account']}/grade/${type}/edit/${id}?semesterId=${searchParams?.semesterId}&courseName=${searchParams?.courseName}`,
    },
    calendar: `${roots['my-account']}/calendar`,
  },
  complete: roots.complete,
  payment: {
    root: (prevPage: PaymentPrevPage) => `/${prevPage}${roots.payment}`,
    success: (prevPage: PaymentPrevPage) => `/${prevPage}${roots.payment}/success`,
  },
};
