'use client';

import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { isEmpty } from 'lodash';
import Link from 'next/link';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { getScheduleTimeTrackingListQueryOptions } from 'studyvibes-api/orval/endpoints/studyVibesRESTAPI';
import { useDeleteTimeTracking } from '@/operation/api/schedule/mutate';
import { useGetTimeTrackingList } from '@/operation/api/schedule/query';
import { paths } from '@/routes/paths';
import Button from '@/ui/component/button';
import SearchCourse from '@/ui/component/search-course';
import SVIcon from '@/ui/component/sv-icon';
import TextArea from '@/ui/component/textarea';
import { B2, B2b, H5b } from '@/ui/component/typography';
import { useSearchCourse } from './hooks/use-search-course';
import useTrackTime from './hooks/use-track-time';
import ManualTimeTrackingForm from './manual-time-tracking-form';
export default function TrackTimeModal() {
  const queryClient = useQueryClient();
  const {
    data: timeTracking = []
  } = useGetTimeTrackingList({
    date: dayjs().format('YYYY-MM-DD')
  });
  const {
    mutateAsync: deleteTimeTracking
  } = useDeleteTimeTracking();
  const {
    courseList,
    search,
    setSearch,
    course
  } = useSearchCourse();
  const {
    start,
    pause,
    formattedTime,
    isPaused,
    isPendingPostTimeTracking
  } = useTrackTime();
  const [isDeletingTrackTimeIds, setIsDeletingTrackTimeIds] = useState<number[]>([]);
  const [trackTimeNotes, setTrackTimeNotes] = useState<string>();
  const startTracking = () => {
    if (course || search === '') {
      start();
    } else {
      toast.error('Please select a subject');
    }
  };
  const pauseTracking = async () => {
    await pause(course?.id, trackTimeNotes);
    setSearch('');
  };
  const onRemoveTimeTrack = async (id: number) => {
    setIsDeletingTrackTimeIds(prev => [...prev, id]);
    try {
      await deleteTimeTracking(id);
      await queryClient.invalidateQueries(getScheduleTimeTrackingListQueryOptions());
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      toast.error('Failed to delete time tracking');
    }
    setIsDeletingTrackTimeIds(prev => prev.filter(value => value !== id));
  };
  return <div className="bg-bg-white rounded-m p-3 w-[400px] max-h-[90vh] hide-scrollbar shadow-s flex flex-col gap-6" data-sentry-component="TrackTimeModal" data-sentry-source-file="track-time-modal.tsx">
      <H5b data-sentry-element="H5b" data-sentry-source-file="track-time-modal.tsx">Track Time</H5b>
      <div className="flex flex-col gap-[10px]">
        <SearchCourse options={courseList} placeholder="Search subjects" value={search} onChange={setSearch} disabled={!isPaused} data-sentry-element="SearchCourse" data-sentry-source-file="track-time-modal.tsx" />
        <div className="p-3 flex justify-between items-center border-solid border-s rounded-s border-border-inverseTertiary">
          <H5b data-sentry-element="H5b" data-sentry-source-file="track-time-modal.tsx">{formattedTime}</H5b>
          {isPaused ? <Button onClick={startTracking} shape="circle" variant="tertiary-transparent" iconOnly>
              <SVIcon name="PlayCircleLFilled" className="text-content-success" />
            </Button> : <Button onClick={pauseTracking} shape="circle" variant="tertiary-transparent" iconOnly disabled={isPendingPostTimeTracking}>
              <SVIcon name="StopCircleLFilled" className="text-content-error" />
            </Button>}
        </div>
        {isPaused ? <ManualTimeTrackingForm disabled={!isPaused} /> : <TextArea label="Notes" value={trackTimeNotes} onChange={e => setTrackTimeNotes(e.target.value)} className="w-full" />}
      </div>
      <div className="flex flex-col gap-[10px]">
        {!isEmpty(timeTracking) && <B2 className="text-content-inverseSecondary">{dayjs().format('MMM, DD')}</B2>}
        {timeTracking?.map(time => <div key={time?.id} className="flex justify-between items-center px-3 py-2 border-solid border-s rounded-s border-border-inverseTertiary">
            <div className="flex flex-col">
              <B2b>{time?.course?.name ? time?.course?.name : 'No Subject Selected'}</B2b>
              <B2 className="text-content-inverseSecondary whitespace-pre">
                {formatTimeDuration(time?.startTime, time?.endTime)}
              </B2>
            </div>
            <Button onClick={() => onRemoveTimeTrack(time?.id)} variant="tertiary-transparent" shape="circle" iconOnly disabled={isDeletingTrackTimeIds.includes(time?.id)}>
              <SVIcon name="XCircleMFilled" className="text-content-error" />
            </Button>
          </div>)}
      </div>
      <Link href={paths.myAccount.calendar} passHref data-sentry-element="Link" data-sentry-source-file="track-time-modal.tsx">
        <Button leadingIcon={<SVIcon name="CalendarDotsMOutlined" />} size="s" variant="tertiary-light" className="w-full" data-sentry-element="Button" data-sentry-source-file="track-time-modal.tsx">
          Go to Calendar
        </Button>
      </Link>
    </div>;
}

// --------------------------------------

dayjs.extend(duration);
const formatTimeDuration = (startTime: string | Date, endTime: string | Date) => {
  const start = dayjs(startTime).format('HH:mm');
  const end = dayjs(endTime).format('HH:mm');
  const timeDiffSeconds = dayjs(endTime).diff(dayjs(startTime), 'seconds');
  const timeDiff = formatTime(timeDiffSeconds);
  return `${start} - ${end}${'     '}${timeDiff}`;
};
const formatTime = (totalSeconds: number) => {
  const totalHours = Math.floor(totalSeconds / 3600).toString().padStart(2, '0');
  const minutes = Math.floor(totalSeconds % 3600 / 60).toString().padStart(2, '0');
  const seconds = (totalSeconds % 60).toString().padStart(2, '0');
  return `${totalHours}h: ${minutes}m: ${seconds}s`;
};