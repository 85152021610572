import { cnm } from '@/utils/cnm';
import * as Icon from './icon';
import { SVIconProps } from './types';
export default function SVIcon({
  name,
  fill,
  className,
  ...props
}: SVIconProps) {
  const IconComponent = name && Icon?.[name];
  const fillColor = `text-${fill}` || 'currentColor';
  return IconComponent ? <span className={cnm('current-color', fillColor, className)} data-sentry-component="SVIcon" data-sentry-source-file="index.tsx">
      {IconComponent({
      ...props,
      fill: 'currentColor'
    })}
    </span> : null;
}