'use client';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import SVIcon from '../component/sv-icon';
const contextClass = {
  success: 'bg-bg-success border border-border-success border-s ',
  error: 'bg-bg-error border border-border-error border-s',
  warning: 'bg-bg-warning border-border-warning border-s',
  info: 'bg-blue-500',
  default: 'bg-bg-primary',
  dark: 'bg-gray-900 text-content-white'
};
const icons = {
  success: <SVIcon name="CheckCircleMFilled" fill="content-success" />,
  error: <SVIcon name="XCircleMFilled" fill="content-error" />,
  warning: <SVIcon name="WarningCircleMFilled" fill="content-warning" />,
  info: <SVIcon name="WarningCircleMFilled" fill="content-warning" />,
  default: <SVIcon name="CheckCircleMFilled" fill="content-success" />
};
const ClientSideToastProvider = () => <ToastContainer className="min-w-fit" toastClassName={context => contextClass[context?.type || 'default'] + 'relative flex px-4 py-3 min-h-10 rounded-m justify-between overflow-hidden cursor-pointer mb-4'} bodyClassName={() => 'flex items-center text-content-inversePrimary text-b1'} hideProgressBar autoClose={3000} closeButton={false} icon={context => <div>{icons[context?.type || 'default']}</div>} pauseOnHover={false} pauseOnFocusLoss={false} data-sentry-element="ToastContainer" data-sentry-component="ClientSideToastProvider" data-sentry-source-file="toast-provider.tsx" />;
export default ClientSideToastProvider;