import { UseMutationResult } from '@tanstack/react-query';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ParseFunction<TInput = unknown, TOutput = unknown> = (input: TInput) => TOutput;
export const useCustomMutation = <TData, TVariables, TError, TContext, TFunction extends ParseFunction<TData>>(mutation: UseMutationResult<TData, TError, TVariables, TContext>, dataPareFunction: TFunction,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
paramParseFunction: ParseFunction<any, TVariables> = data => data as TVariables) => {
  type DataParseResult = TFunction extends ((input: TData) => infer R) ? R : never;
  return {
    ...mutation,
    data: mutation.data ? dataPareFunction(mutation.data) as DataParseResult : undefined,
    mutate: (data: Parameters<typeof paramParseFunction>[0], options?: Parameters<typeof mutation.mutate>[1]) => mutation.mutate(paramParseFunction(data), options),
    mutateAsync: async (data: Parameters<typeof paramParseFunction>[0], options?: Parameters<typeof mutation.mutateAsync>[1]) => {
      const response = await mutation.mutateAsync(paramParseFunction(data), options);
      return dataPareFunction(response) as DataParseResult;
    }
  };
};