import * as amplitude from '@amplitude/analytics-browser';
import { BrowserOptions } from '@amplitude/analytics-types';

export class Amplitude {
  private _instance: typeof amplitude = amplitude;

  constructor(apiKey: string, options?: BrowserOptions) {
    this._instance.init(apiKey, options);
  }

  public getInstance() {
    return this._instance;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public trackEvent(eventName: string, eventProperties?: Record<string, any>) {
    this._instance.track(eventName, eventProperties);
  }
}
