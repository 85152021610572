'use client';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_SECRET_KEY);
export default function StripeProvider({
  children
}: {
  children: React.ReactNode;
}) {
  return <Elements stripe={stripePromise} data-sentry-element="Elements" data-sentry-component="StripeProvider" data-sentry-source-file="stripe-provider.tsx">{children}</Elements>;
}