import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import {
  ChargeHistoryQBRead,
  toChargeHistoryQBRead,
} from 'entity-convert/entity/charge-history-qb-read';
import {
  ChargeHistoryTutoringRead,
  toChargeHistoryTutoringRead,
} from 'entity-convert/entity/charge-history-tutoring-read';
import { toPaymentMethod } from 'entity-convert/entity/payment-method';
import { toSubjectWithCourse } from 'entity-convert/entity/subject-with-course';
import {
  SubscriptionCancellationRequest,
  toSubscriptionCancellationRequest,
} from 'entity-convert/entity/subscription-cancellation-request';
import { toSubscriptionProduct } from 'entity-convert/entity/subscription-product';
import {
  SubscriptionProductsListParams,
  toSubscriptionProductsListParams,
} from 'entity-convert/entity/subscription-products-list-params';
import { SubscriptionQB, toSubscriptionQB } from 'entity-convert/entity/subscription-qb';
import {
  SubscriptionTutoring,
  toSubscriptionTutoring,
} from 'entity-convert/entity/subscription-tutoring';
import { isEmpty } from 'lodash';
import {
  getSubscriptionPaymentMethodListQueryKey,
  getSubscriptionPaymentQuestionBankListQueryKey,
  getSubscriptionPaymentTutoringListQueryKey,
  getSubscriptionQuestionBankListQueryKey,
  questionBankSubjectList,
  subscriptionPaymentMethodList,
  subscriptionPaymentQuestionBankList,
  subscriptionPaymentTutoringList,
  subscriptionQuestionBankList,
  subscriptionRequestCancelRetrieve,
  subscriptionTutoringList,
  tutoringRecommendRetrieve,
  useSubscriptionProductsList,
  useSubscriptionProductsRetrieve,
  useSubscriptionRequestCancelList,
  useSubscriptionRequestCancelRetrieve,
  useSubscriptionTutoringList,
} from 'studyvibes-api/orval/endpoints/studyVibesRESTAPI';
import {
  SubscriptionRequestCancelListRequestType,
  SubscriptionRequestCancelListServiceType,
} from 'studyvibes-api/orval/models';

import { QUERY_KEYS } from '@/operation/query-keys';

export const useGetSubscriptionProductList = (params?: SubscriptionProductsListParams) => {
  return useSubscriptionProductsList(toSubscriptionProductsListParams(params), {
    query: {
      select: (data) => data.map(toSubscriptionProduct),
    },
  });
};

export const useGetSubscriptionProduct = (id?: number) => {
  return useSubscriptionProductsRetrieve(id, {
    query: {
      select: (data) => toSubscriptionProduct(data),
      enabled: Boolean(id),
    },
  });
};

export const useGetPaymentMethodList = (params: { subscriptionId?: string }) => {
  return useSuspenseQuery({
    queryKey: getSubscriptionPaymentMethodListQueryKey({ subscription_id: params?.subscriptionId }),
    queryFn: async () => {
      try {
        const data = await subscriptionPaymentMethodList({
          subscription_id: params?.subscriptionId,
        });
        return data?.map(toPaymentMethod);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        return [];
      }
    },
  });
};
/**
 * 결제한 문제은행 리스트는 상태가 변경될 때마다(취소, 환불, 환불 요청 등) 기존 데이터의 status가 갱신되는 것이 아니라
 * 새로운 데이터가 추가되는 방식으로 저장됩니다. 따라서 같은 결제 건(Charge ID)에 대해 중복된 데이터가 존재할 수 있습니다.
 *
 * 이를 방지하기 위해 chargeId를 기준으로 중복 데이터를 제거하고, 가장 최신(updatedAt 기준) 데이터만 남깁니다.
 * 또한, 각 문제은행 데이터에 subjectName(과목명)을 추가합니다.
 *
 * @returns 문제은행 결제 내역 리스트
 * - 동일한 chargeId에 대한 중복 데이터를 제거하고, 가장 최신 데이터만 포함합니다.
 * - 각 항목에 해당하는 과목명을 subjectName 필드로 추가합니다.
 */
export const useGetSubscriptionPaymentQuestionBankList = () => {
  return useSuspenseQuery({
    queryKey: getSubscriptionPaymentQuestionBankListQueryKey(),
    queryFn: async () => {
      try {
        const data = await subscriptionPaymentQuestionBankList();
        const subjects = await questionBankSubjectList();
        const subjectList = subjects
          .map(toSubjectWithCourse)
          ?.filter((subject) => subject.courses?.length > 0);

        const parsed = data.items.map(toChargeHistoryQBRead);
        const chargeIdMap = new Map<string, ChargeHistoryQBRead & { subjectName: string }>();

        parsed.forEach((item) => {
          const existingItem = chargeIdMap.get(item.chargeId);
          if (!existingItem || new Date(item.updatedAt) > new Date(existingItem.updatedAt)) {
            const subject = subjectList?.find((subject) => subject.id === item.course.subjectId);
            chargeIdMap.set(item.chargeId, { ...item, subjectName: subject?.name });
          }
        });

        return Array.from(chargeIdMap.values());
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        return [];
      }
    },
  });
};

/**
 * 결제한 튜터링 내역 리스트는 상태가 변경될 때마다(취소, 환불, 환불 요청 등) 기존 데이터의 status가 갱신되는 것이 아니라
 * 새로운 데이터가 추가되는 방식으로 저장됩니다. 따라서 같은 결제 건(Charge ID)에 대해 중복된 데이터가 존재할 수 있습니다.
 *
 * 이를 방지하기 위해 chargeId를 기준으로 중복 데이터를 제거하고, 가장 최신(updatedAt 기준) 데이터만 남깁니다.
 *
 * @returns 튜터링 결제 내역 리스트
 * - 동일한 chargeId에 대한 중복 데이터를 제거하고, 가장 최신 데이터만 포함합니다.
 */
export const useGetSubscriptionPaymentTutoringList = () => {
  return useSuspenseQuery({
    queryKey: getSubscriptionPaymentTutoringListQueryKey(),
    queryFn: async () => {
      try {
        const data = await subscriptionPaymentTutoringList();
        const parsed = data?.items?.map(toChargeHistoryTutoringRead);

        const chargeIdMap = new Map<string, ChargeHistoryTutoringRead>();

        parsed?.forEach((item) => {
          const existingItem = chargeIdMap.get(item.chargeId);
          if (!existingItem || new Date(item.updatedAt) > new Date(existingItem.updatedAt)) {
            chargeIdMap.set(item.chargeId, item);
          }
        });

        return Array.from(chargeIdMap.values());

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        return [];
      }
    },
  });
};

type InferSubscriptionQBSelectReturn<TSelect> = TSelect extends (data: SubscriptionQB[]) => infer R
  ? R
  : SubscriptionQB[];

export const useGetSubscriptionQuestionBankList = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TSelect extends (data: SubscriptionQB[]) => any,
>(
  params: { status?: string; cancelAtPeriodEnd?: boolean } = {},
  { select }: { select?: TSelect } = {},
) => {
  return useSuspenseQuery({
    queryKey: getSubscriptionQuestionBankListQueryKey({
      cancel_at_period_end: params?.cancelAtPeriodEnd,
      status: params?.status,
    }),
    queryFn: async (): Promise<InferSubscriptionQBSelectReturn<TSelect>> => {
      try {
        const data = await subscriptionQuestionBankList({
          cancel_at_period_end: params?.cancelAtPeriodEnd,
          status: params?.status,
        });
        const parsed = data.items.map(toSubscriptionQB);
        return select ? select(parsed) : (parsed as InferSubscriptionQBSelectReturn<TSelect>);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        return [] as InferSubscriptionQBSelectReturn<TSelect>;
      }
    },
  });
};

type InferSubscriptionTutoringSelectReturn<TSelect> = TSelect extends (
  data: SubscriptionTutoring[],
) => infer R
  ? R
  : SubscriptionTutoring[];

export const useGetSubscriptionTutoringList = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TSelect extends (data: SubscriptionTutoring[]) => any,
>({ select }: { select?: TSelect } = {}) => {
  return useSubscriptionTutoringList(undefined, {
    query: {
      select: (data): InferSubscriptionTutoringSelectReturn<TSelect> => {
        const parsed = data.items.map(toSubscriptionTutoring);
        return select ? select(parsed) : (parsed as InferSubscriptionTutoringSelectReturn<TSelect>);
      },
    },
  });
};

type InferSelectReturn<TSelect> = TSelect extends (
  data: SubscriptionCancellationRequest[],
) => infer R
  ? R
  : SubscriptionCancellationRequest[];

export const useGetSubscriptionCancelList = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TSelect extends (data: SubscriptionCancellationRequest[]) => any,
>(
  {
    requestType,
    serviceType,
  }: {
    requestType?: SubscriptionRequestCancelListRequestType;
    serviceType?: SubscriptionRequestCancelListServiceType;
  } = {},
  { select }: { select?: TSelect } = {},
) => {
  return useSubscriptionRequestCancelList(
    { request_type: requestType, service_type: serviceType },
    {
      query: {
        select: (data): InferSelectReturn<TSelect> => {
          const parsed = data.items.map(toSubscriptionCancellationRequest);
          return select ? select(parsed) : (parsed as InferSelectReturn<TSelect>);
        },
      },
    },
  );
};

export const useSubscriptionRequestCancel = (subscriptionId: string) => {
  return useSubscriptionRequestCancelRetrieve(subscriptionId, {
    query: {
      select: toSubscriptionCancellationRequest,
      enabled: Boolean(subscriptionId),
    },
  });
};

/**
 *
 * @param questionBank 결제한 문제은행 리스트 :`/subscription/payment/question_bank` API로부터 받은 데이터
 * @returns 문제은행 구독 리스트 중 구독 취소 가능한 과목리스트를 반환합니다.
 * - 취소, 환불 요청을 한 번도 한 적이 없거나, 환불 요청이 거절된 과목만 반환합니다.
 */
export const useGetQuestionBankCancelRequestList = (
  questionBank?: (ChargeHistoryQBRead & { subjectName: string })[],
) => {
  return useQuery({
    queryKey: QUERY_KEYS.getQBCancelRequestList(),
    queryFn: async () => {
      const subscriptionQb = await subscriptionQuestionBankList({
        cancel_at_period_end: false,
        status: 'active',
      });
      const data = await Promise.all(
        subscriptionQb?.items?.map(async (item) => {
          try {
            const cancelRequest = await subscriptionRequestCancelRetrieve(
              item?.payment_method?.subscription_id,
            );
            // 취소, 환불 요청이 승인 되었거나 대기 중인 경우 통과되면 안된다
            if (cancelRequest?.status === 'APPROVED' || cancelRequest?.status === 'PENDING') {
              return null;
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
          } catch (e) {
            // 404 에러가 발생해야 취소/환불 요청한 적 없는 것이므로 통과되어야 한다.
          }
          // 환불 요청한 적이 없거나 환불 요청이 거절되었을 경우 통과되어야 한다.
          const has = questionBank?.find(
            (qb) =>
              qb.paymentMethod?.subscriptionId === item?.payment_method?.subscription_id &&
              (qb.statusDisplay === 'COMPLETED' || qb.statusDisplay === 'UNKNOWN'),
          );

          if (has) {
            return {
              chargeId: has?.chargeId,
              subjectName: has?.subjectName,
              subscriptionId: item?.payment_method?.subscription_id,
              course: {
                name: has?.course?.name,
                level: has?.course?.level,
              },
            };
          }
        }),
      );

      return data.filter(Boolean);
    },
    enabled: Boolean(!isEmpty(questionBank)),
  });
};

/**
 *
 * @param tutoring 결제한 튜터링 리스트 :`/subscription/payment/tutoring` API로부터 받은 데이터
 * @returns 튜터링 구독 리스트 중 구독 취소 가능한 과목리스트를 반환합니다.
 * - 취소, 환불 요청을 한 번도 한 적이 없거나, 환불 요청이 거절된 과목만 반환합니다.
 */
export const useGetTutoringCancelRequestList = (tutoring?: ChargeHistoryTutoringRead[]) => {
  return useQuery({
    queryKey: QUERY_KEYS.getTutoringCancelRequestList(),
    queryFn: async () => {
      const subscriptionTutoring = await subscriptionTutoringList({
        cancel_at_period_end: false,
        status: 'active',
      });

      const data = await Promise.all(
        subscriptionTutoring?.items?.map(async (item) => {
          try {
            const cancelRequest = await subscriptionRequestCancelRetrieve(
              item?.payment_method?.subscription_id,
            );
            // 취소, 환불 요청이 승인 되었거나 대기 중인 경우 통과되면 안된다
            if (cancelRequest?.status === 'APPROVED' || cancelRequest?.status === 'PENDING') {
              return null;
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
          } catch (e) {
            // 404 에러가 발생해야 취소/환불 요청한 적 없는 것이므로 통과되어야 한다.
          }
          const recommend = await tutoringRecommendRetrieve(item?.tutoring?.tutor_recommendation);
          // 환불 요청한 적이 없거나 환불 요청이 거절되었을 경우
          const chargeId = tutoring?.find((t) => {
            return (
              t?.paymentMethod?.subscriptionId === item?.payment_method?.subscription_id &&
              (t.statusDisplay === 'COMPLETED' || t.statusDisplay === 'UNKNOWN')
            );
          })?.chargeId;
          // 그 외 상태에선 통과할 수 없어야 한다
          if (!chargeId) return null;

          return {
            chargeId,
            subscriptionId: item?.payment_method?.subscription_id,
            subject: recommend?.course?.subject,
            course: recommend?.course,
          };
        }),
      );
      return data.filter(Boolean);
    },
    enabled: Boolean(!isEmpty(tutoring)),
  });
};
