'use client';

import Image from 'next/image';
import Link from 'next/link';
import ClientSideRender from '@/lib/client-side-render';
import { useGetSubjectList } from '@/operation/api/question-bank/query';
import { useGetSubscriptionQuestionBankList } from '@/operation/api/subscription/query';
import { useAuth } from '@/provider/auth-provider';
import GoogleLoginProvider from '@/provider/google-login-provider';
import { paths } from '@/routes/paths';
import Button from '@/ui/component/button';
import SVIcon from '@/ui/component/sv-icon';
import { B1, B1b } from '@/ui/component/typography';
import DropdownMenu from './dropdown-menu';
export default function Header() {
  const {
    isLoggedIn,
    userInfo
  } = useAuth();
  const {
    data: isQbMembership
  } = useGetSubscriptionQuestionBankList({
    status: 'active'
  }, {
    select: (data = []) => data?.length > 0
  });
  const {
    data: subjectList = []
  } = useGetSubjectList({
    inService: true
  });
  return <div className="flex gap-4 bg-bg-white px-10 py-4 shadow-xs items-center z-header" data-sentry-component="Header" data-sentry-source-file="index.tsx">
      <Link href="/" data-sentry-element="Link" data-sentry-source-file="index.tsx">
        <span className="text-h5 font-extrabold">STUDY</span>
        <span className="text-h5 font-medium">VIBES</span>
      </Link>

      <div id="gap" className="h-full w-4" />

      <DropdownMenu subjectList={subjectList} data-sentry-element="DropdownMenu" data-sentry-source-file="index.tsx">
        <div className="text-h5b">Subjects</div>
        <div className="transition-transform duration-500 ease-in-out rotate-0 group-data-[state=open]:rotate-180">
          <SVIcon name="CaretDownMOutlined" data-sentry-element="SVIcon" data-sentry-source-file="index.tsx" />
        </div>
      </DropdownMenu>

      <Link href="/tutoring" className="relative text-h5b focus:outline-none" data-sentry-element="Link" data-sentry-source-file="index.tsx">
        Tutoring
      </Link>

      <div className="ml-auto items-center flex gap-4 h-full">
        {isLoggedIn ? <ClientSideRender>
            <Link href={paths.myAccount.profile}>
              <B1 className="flex gap-2 items-center">
                Welcome, <B1b>{userInfo?.firstName}</B1b>
                <Image src={userInfo?.picture ?? '/image/profile-blank.png'} width={32} height={32} alt="profile" className="w-8 h-8 rounded-full object-cover relative" />
              </B1>
            </Link>
          </ClientSideRender> : <GoogleLoginProvider className="w-[48px] overflow-hidden">Sign in</GoogleLoginProvider>}

        <div className="w-[1px] min-h-[38px] h-full self-stretch bg-border-inverseQuaternary" />
        <Link href="/sign-up" passHref data-sentry-element="Link" data-sentry-source-file="index.tsx">
          <Button variant="primary-light" className="text-b1 px-4" shape="circle" data-sentry-element="Button" data-sentry-source-file="index.tsx">
            Become a{' '}
            <ClientSideRender data-sentry-element="ClientSideRender" data-sentry-source-file="index.tsx">
              <span className="text-b1b">StudyViber{isLoggedIn && !isQbMembership && '+'}</span>
            </ClientSideRender>
          </Button>
        </Link>
      </div>
    </div>;
}