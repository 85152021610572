import type { RefObject } from 'react';
import { useEventListener } from './use-event-listener';
type EventType = 'mousedown' | 'mouseup' | 'touchstart' | 'touchend';

/**
 * 특정 요소 밖에서 발생한 클릭을 처리하는 커스텀 훅입니다.
 * @param {RefObject<T> | RefObject<T>[]} ref - 클릭 외부 감지를 위해 감시할 React ref 객체(들)입니다.
 * @param {(event: MouseEvent | TouchEvent) => void} handler - 요소 외부에서 클릭이 발생했을 때 실행할 콜백 함수입니다.
 * @param {EventType} [eventType] - 감시할 마우스 이벤트 타입 (선택 사항, 기본값은 'mousedown'입니다).
 * @returns {void}
 * @example
 * const containerRef = useRef(null);
 * useOnClickOutside([containerRef], () => {
 *   // 컨테이너 외부에서 클릭이 발생했을 때 처리합니다.
 * });
 */
export function useOnClickOutside<T extends HTMLElement = HTMLElement>(ref: RefObject<T> | RefObject<T>[], handler: (event: MouseEvent | TouchEvent) => void, eventType: EventType = 'mousedown'): void {
  useEventListener(eventType, event => {
    const target = event.target as Node;

    // Do nothing if the target is not connected element with document
    if (!target || !target.isConnected) {
      return;
    }
    const isOutside = Array.isArray(ref) ? ref.every(r => r.current && !r.current.contains(target)) : ref.current && !ref.current.contains(target);
    if (isOutside) {
      handler(event);
    }
  });
}